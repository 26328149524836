import React from 'react'
import { Helmet } from 'react-helmet'
import Container from '../../components/Container'
import SocialSnippet from '../../components/SocialSnippet'
import styles from './Contacts.module.css'

export default () => {
  return (
    <div className={styles['Contacts']}>
      <Helmet>
        <title>Контакты АлексКомп: адрес, телефон, схема проезда, график работы</title>
        <meta name="description" content="Контактная информация компании АлексКомп. Схема проезда, адрес, телефон. График работы и описание проезда на личном и общественном транспортом." />
      </Helmet>
      <Container>
        <Container className={styles['Contacts__Inner']}>
          <h1 className={styles['Contacts__Heading']}>Контакты</h1>
          <div className={styles['Contacts__Row']}>
            <div className={styles['Contacts__Column']}>
              <h2 className={styles['Contacts__Title']}>Где находимся</h2>
              <p>Ул. Центральная, 90<br/>Возле цветочного рынка</p>
            </div>
            <div className={styles['Contacts__Column']}>
              <h2 className={styles['Contacts__Title']}>Как связаться</h2>
              <p>Тел.: +38 (050) 99 37 009</p>
            </div>
            <div className={styles['Contacts__Column']}>
              <h2 className={styles['Contacts__Title']}>Когда работаем</h2>
              <p>По будням c 9 до 18<br/>По субботам c 9 до 17<br/>В воскресенье закрыты</p>
            </div>
            <div className={styles['Contacts__Column']}>
              <SocialSnippet />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
}
